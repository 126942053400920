import {setAppinfoItem} from "@/isr-framework/apis/project-config";
import {projectResponseInterceptors} from "@/isr-framework/apis/request";
import {simulateRouter, useSimulateRoute} from "@/simulate/js/controller";
import {ElMessage} from "element-plus";
import {SimulateProjectConfig} from "@/simulate/js/constant/constant";

function getNumberAndChar(str) {
    return str.replace(/[^a-z\d]/ig, "")
}

export const userdemo = {
    avatarUrl: "",
    city: "",
    country: "",
    gender: 0,
    is_demote: true,
    language: "",
    nickname: "微信用户",
    province: ""
}

//项目运行参数配置
export const defaultConfig = {
    sellerid: 1000,
    defaultUserid: 800001,
    pageSize: 20,
    /*request 请求超时时间*/
    requet_timeoutTime: 30 * 1000,
    clearbuffer: false,
    secret: "ISRAES1234567890",
    /*是否加密传输，true加密，false不加密*/
    http_encrypt: false,
    /*初始化数据保存时间*/
    initdata_reserve_time: 120 * 1000,
    wx_logincode: "ABCDE123456780" + getNumberAndChar(window.location.hostname),
    userDemo: userdemo
}


export const setAppinfo = () => {
    setAppinfoItem("mlpmis", "isr-core", "vue3-web", "mlqx", defaultConfig)
    const simulateInterceptors = (response) => {
        if (!response.config.url.includes("/isr-framework") && !response.config.url.includes("/isr-mlpmis")) {
            if (response.data.retcode === 9990 || response.data.retcode === 9991 || response.data.retcode === 500) {
                ElMessage.error('登录过期，请重新登录！')
                useSimulateRoute().useSimulateState.user.userid = ""
                simulateRouter(SimulateProjectConfig.loginname, {}, 1)
            }
        }
    }
    projectResponseInterceptors.push(simulateInterceptors)
}

