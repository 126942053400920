export const frameworkRouter = [
    {
        path: '/isradmin/config-diskspace',
        name: 'diskspace',
        component: () => import('@/views/isr-mlpmis/SystemDiskSpaceView'),
        meta: {
            title: "磁盘剩余空间记录管理"
        }
    },
    {
        path: '/isradmin/config-baseconfig',
        name: 'configpara',
        component: () => import('@/views/isr-framework/config/ConfigParaManager'),
        meta: {
            title: "基本参数管理"
        }
    },
    {
        path: '/isradmin/config-dropdown',
        name: 'configdropdown',
        component: () => import('@/views/isr-framework/config/ConfigDropdownManager'),
        meta: {
            title: "下拉框参数管理"
        }
    },
    {
        path: '/isradmin/config-banner',
        name: 'configbanner',
        component: () => import('@/views/isr-framework/config/ConfigBannerManager'),
        meta: {
            title: "轮播图管理"
        }
    },
    {
        path: '/isradmin/madminlogger',
        name: 'madminlogger',
        component: () => import('@/views/isr-framework/config/AdminConfigLoginManager'),
        meta: {
            title: "总后台登录日志管理"
        }
    },
    {
        path: '/isradmin/sellerlogger',
        name: 'sellerlogger',
        component: () => import('@/views/isr-framework/config/AdminSellerLoginManager'),
        meta: {
            title: "全部商户登入日志管理"
        }
    },
    {
        path: '/isradmin/config-setmenu',
        name: 'configmenu',
        component: () => import('@/views/isr-framework/config/ConfigMenuManager'),
        meta: {
            title: "菜单设置"
        }
    },
    {
        path: '/isradmin/config-menu-record',
        name: 'configmenulog',
        component: () => import('@/views/isr-framework/config/ConfigMenuLogManager'),
        meta: {
            title: "菜单配置记录"
        }
    },
    {
        path: '/isradmin/config-requestapi',
        name: 'menuapi',
        component: () => import('@/views/isr-framework/config/ConfigMenuApiManager'),
        meta: {
            title: "API请求记录"
        }
    },
    {
        path: '/isradmin/config-adminuser',
        name: 'isradminuser',
        component: () => import('@/views/isr-framework/config/ConfigUserManager'),
        meta: {
            title: "用户管理"
        }
    },
    {
        path: '/isradmin/config-rules',
        name: 'isrrole',
        component: () => import('@/views/isr-framework/config/ConfigRoleManager'),
        meta: {
            title: "角色管理"
        }
    },
    {
        path: '/isradmin/config-agreement',
        name: 'isragreement',
        component: () => import('@/views/isr-framework/config/ConfigAgreeMent'),
        meta: {
            title: "协议管理"
        }
    },
    {
        path: '/isradmin/config-helptype',
        name: 'helptype',
        component: () => import('@/views/isr-framework/config/ConfigHelpManager'),
        meta: {
            title: "帮助类型"
        }
    },
    {
        path: '/isradmin/config-helpcontent',
        name: 'helpcontent',
        component: () => import('@/views/isr-framework/config/ConfigHelpContentManager'),
        meta: {
            title: "帮助内容"
        }
    },
    {
        path: '/isradmin/config-city',
        name: 'city',
        component: () => import('@/views/isr-framework/config/ConfigLocationCity'),
        meta: {
            title: "城市管理"
        }
    },
    {
        path: '/isradmin/config-province',
        name: 'province',
        component: () => import('@/views/isr-framework/config/ConfigLocationProvince'),
        meta: {
            title: "省份管理"
        }
    },
    {
        path: '/isradmin/config-district',
        name: 'district',
        component: () => import('@/views/isr-framework/config/ConfigLocationDistrict'),
        meta: {
            title: "地区管理"
        }
    },
    {
        path: '/isradmin/config-sms',
        name: 'sms',
        component: () => import('@/views/isr-framework/config/ConfigSmsManager'),
        meta: {
            title: "短信验证管理"
        }
    },
    {
        path: '/isradmin/config-email',
        name: 'email',
        component: () => import('@/views/isr-framework/config/ConfigEmailSmsManager'),
        meta: {
            title: "邮箱验证管理"
        }
    },

    {
        path: '/isradmin/config-holiday',
        name: 'holiday',
        component: () => import('@/views/isr-framework/config/ConfigHolidayManager'),
        meta: {
            title: "节假日管理"
        }
    },
    {
        path: '/isradmin/config-authkeyword',
        name: 'authkeyword',
        component: () => import('@/views/isr-framework/config/ConfigAuthkeywordManager'),
        meta: {
            title: "审核关键字"
        }
    },
    {
        path: '/isradmin/config-express',
        name: 'express',
        component: () => import('@/views/isr-framework/config/ConfigExpressCompanyManager'),
        meta: {
            title: "快递公司管理"
        }
    },
    {
        path: '/isradmin/conifg-nation',
        name: 'config-nation',
        component: () => import('@/views/isr-framework/config/ConfigNationManager'),
        meta: {
            title: "民族管理"
        }
    },
    {
        path: '/isradmin/config-notification',
        name: 'notification',
        component: () => import('@/views/isr-framework/config/ConfigNotificationManager'),
        meta: {
            title: "消息通知"
        }
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('@/views/isr-framework/config/ErrorPage'),
        meta: {
            title: "未知页面"
        }
    }
]

