import {createRouter, createWebHistory} from "vue-router";
import {frameworkRouter} from "@/isr-framework/router";

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/isr-mlpmis/Login/IsrUserLogin'),
        meta: {
            title: "主页"
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/isr-mlpmis/Login/IsrUserLogin'),
        meta: {
            title: "用户登录"
        }
    },
    {
        path: '/index',
        name: 'homeIndex',
        component: () => import('@/views/isr-framework/menu/MenuMain'),
        meta: {
            title: "默蓝项目后台管理系统"
        },
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('@/views/isr-framework/WorkMain/IsrWorkMain'),
                meta: {
                    title: "工作台"
                }
            },
            {
                path: '/mlpmis-admin/account/go-logout-home',
                name: 'logout',
                component: () => import('@/views/isr-mlpmis/logoutListView'),
                meta: {
                    title: "已注销用户列表"
                }
            },
            {
                path: '/mlpmis-admin/account/userinfo/go-userinfo-home',
                name: 'loginuser',
                component: () => import('@/views/isr-mlpmis/loginUserListView'),
                meta: {
                    title: "注册用户列表"
                }
            },
            {
                path: '/mlpmis-admin/order/go-order-home',
                name: 'payview',
                component: () => import('@/views/isr-mlpmis/PayListView'),
                meta: {
                    title: "订单管理"
                }
            },
            {
                path: '/mlpmis-admin/projects/go-project-home',
                name: 'project',
                component: () => import('@/views/isr-mlpmis/ProjectHomeView'),
                meta: {
                    title: "项目管理"
                }
            },
            {
                path: '/mlpmis-admin/org/go-org-logoff-home',
                name: 'orgout',
                component: () => import('@/views/isr-mlpmis/OrgOutHistoryView'),
                meta: {
                    title: "注销组织"
                }
            },
            {
                path: '/mlpmis-admin/org/go-org-home',
                name: 'orglist',
                component: () => import('@/views/isr-mlpmis/OrgListView'),
                meta: {
                    title: "组织列表"
                }
            },
            {
                path: '/mlpmis-admin/system/right/go-allright-home',
                name: 'allright',
                component: () => import('@/views/isr-mlpmis/RuleListView'),
                meta: {
                    title: "权限配置管理"
                }
            },
            {
                path: '/mlpmis-admin/system/right/org-role-right/go-roleright-home',
                name: 'roleright',
                component: () => import('@/views/isr-mlpmis/RuleUserListView'),
                meta: {
                    title: "项目角色权限配置管理"
                }
            },
            {
                path: '/mlpmis-admin/system/config/go-tasktype-home',
                name: 'tasktype',
                component: () => import('@/views/isr-mlpmis/TaskTypeView'),
                meta: {
                    title: "任务类型"
                }
            },
            {
                path: '/mlpmis-admin/system/config/go-bugtype-home',
                name: 'bugtype',
                component: () => import('@/views/isr-mlpmis/BugsTypeView'),
                meta: {
                    title: "缺陷类型"
                }
            },
            {
                path: '/mlpmis-admin/system/config/go-doctype-home',
                name: 'doctype',
                component: () => import('@/views/isr-mlpmis/DocTypeListView'),
                meta: {
                    title: "文档类型"
                }
            },
            {
                path: '/mlpmis-admin/system/right/org-project-right/go-projectright-home',
                name: 'projectright',
                component: () => import('@/views/isr-mlpmis/RuleObjectView'),
                meta: {
                    title: "项目权限配置管理"
                }
            },
            {
                path: '/mlpmis-admin/account/download/go-downloadprice-home',
                name: 'vipstanderd',
                component: () => import('@/views/isr-mlpmis/VipStandardListView'),
                meta: {
                    title: "VIP下载价格管理"
                }
            },
            {
                path: '/mlpmis-admin/account/uservip/go-uservip-home',
                name: 'vipuser',
                component: () => import('@/views/isr-mlpmis/VipUserListView'),
                meta: {
                    title: "VIP会员管理"
                }
            },
            {
                path: '/mlpmis-admin/regex-template/go-regex-home',
                name: 'regex',
                component: () => import('@/views/isr-mlpmis/RegexTemplateView'),
                meta: {
                    title: "正则表达式"
                }
            },
            {
                path: '/mlpmis-admin/system/config/bugs/go-bugkpi-home',
                name: 'bugkpi',
                component: () => import('@/views/isr-mlpmis/BugKpiView'),
                meta: {
                    title: "缺陷绩效"
                }
            },
            {
                path: '/mlpmis-admin/system/logger/loginlog/go-logginlog-home',
                name: 'logginlog',
                component: () => import('@/views/isr-mlpmis/LoginLogListView'),
                meta: {
                    title: "登录日志"
                }
            },
            {
                path: '/mlpmis-admin/system/logger/webillegal/go-webillegal-home',
                name: 'webillegal',
                component: () => import('@/views/isr-mlpmis/NoAccessLoginView'),
                meta: {
                    title: "非法访问"
                }
            },
            {
                path: '/mlpmis-admin/account/go-loginerr-home',
                name: 'loginerr',
                component: () => import('@/views/isr-mlpmis/ErrorLoginView'),
                meta: {
                    title: "登录账号密码异常管理"
                }
            },
            {
                path: '/mlpmis-admin/system/tools/go-pdf2word-home',
                name: 'pdf2word',
                component: () => import('@/views/isr-mlpmis/PDFtoWordView'),
                meta: {
                    title: "PDF转换WORD监控"
                }
            },
            {
                path: '/mlpmis-admin/system/tools/go-pdf2img-home',
                name: 'pdf2img',
                component: () => import('@/views/isr-mlpmis/PDFtoIMGView'),
                meta: {
                    title: "PDF转换IMG监控"
                }
            },
            {
                path: '/mlpmis-admin/system/tools/go-img2ico-home',
                name: 'img2ico',
                component: () => import('@/views/isr-mlpmis/IMGtoICO'),
                meta: {
                    title: "IMG转ICO监控"
                }
            },
            {
                path: '/mlpmis-admin/system/email/go-email-home',
                name: 'emailtask',
                component: () => import('@/views/isr-mlpmis/EmailView'),
                meta: {
                    title: "邮件任务管理"
                }
            },
            {
                path: '/mlpmis-admin/regex-template/go-regexsearch-home',
                name: 'regexsearch',
                component: () => import('@/views/isr-mlpmis/RegexSearchView'),
                meta: {
                    title: "正则表达式搜索记录"
                }
            },
            {
                path: '/mlpmis-admin/blogs/go-blogauth-home',
                name: 'blogauth',
                component: () => import('@/views/isr-mlpmis/BlogsAuthView'),
                meta: {
                    title: "公开博客审核"
                }
            },
            {
                path: '/mlpmis-admin/projects/go-projectauth-home',
                name: 'projectauth',
                component: () => import('@/views/isr-mlpmis/ProjectAuthView'),
                meta: {
                    title: "公开项目审核"
                }
            },
            {
                path: '/isradmin/madminlogger',
                name: 'madminlogger',
                component: () => import('@/views/isr-framework/config/AdminConfigLoginManager'),
                meta: {
                    title: "总后台登录日志管理"
                }
            },
            {
                path: '/isradmin/sellerlogger',
                name: 'sellerlogger',
                component: () => import('@/views/isr-framework/config/AdminSellerLoginManager'),
                meta: {
                    title: "全部商户登入日志管理"
                }
            },
            {
                path: '/mlpmis-admin/system/go-feedback-home',
                name: 'feedback',
                component: () => import('@/views/isr-mlpmis/FeedbackView'),
                meta: {
                    title: "意见/建议"
                }
            },
            {
                path: '/mlpmis-admin/system/advert/go-advcrl-home',
                name: 'advcrl',
                component: () => import('@/views/isr-mlpmis/AdvertView'),
                meta: {
                    title: "广告列表"
                }
            },
            {
                path: '/mlpmis-admin/system/schedule/go-html-home',
                name: 'htmlhome',
                component: () => import('@/views/isr-mlpmis/HtmlControllerView'),
                meta: {
                    title: "HTML控制"
                }
            },
            {
                path: '/mlpmis-admin/system/advert/go-adv-home',
                name: 'advhome',
                component: () => import('@/views/isr-mlpmis/AdvControllerView'),
                meta: {
                    title: "广告控制"
                }
            },
            {
                path: '/mlpmis-admin/go-apply-reviewcode',
                name: 'reviewcode',
                component: () => import('@/views/isr-mlpmis/ReviewCodeView'),
                meta: {
                    title: "源码申请预览记录"
                }
            },
            {
                path: '/simulate',
                name: 'simulate',
                component: () => import('@/simulate/views/main/MLMain'),
                meta: {
                    title: "模拟器管理"
                }
            },
            {
                path: '/error',
                name: 'error',
                component: () => import('@/views/isr-framework/config/ErrorPage'),
                meta: {
                    title: "未知页面"
                }
            },
            {
                path: '/mlpmis-admin/system/config/blogs/go-blogkey-home',
                name: 'blogkey',
                component: () => import('@/views/isr-mlpmis/BlogKeyView'),
                meta: {
                    title: "博客分类"
                }
            },
        ]
    },
]

const getRouter = () => {
    let find = routes.find(item => item.path === "/index");
    frameworkRouter.forEach(item => {
        find.children.push(item)
    })
    return createRouter({
        history: createWebHistory(import.meta.env.VITE_URL),
        mode: history,
        routes
    })
}

const router = getRouter()

const noNeedLoginPage = ['login', 'homeIndex']
router.beforeEach((to, from, next) => {
    const routeExists = router.getRoutes().some(route => route.path === to.path)
    if (!routeExists) {
        next('/error')
    }
    const user = JSON.parse(localStorage.getItem('user'))
    if (noNeedLoginPage.includes(to.name)) {
        next()
        return;
    }
    if (user) {
        next()
    } else {
        next('/login')
    }
    if (user.rdshopname && to.meta.title) {
        document.title = user.rdshopname + to.meta.title
    }
    if (to.meta.title === '主页') {
        next('/login')
    }
})

export default router